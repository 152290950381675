<template>
  <div v-if="section" class="row">
    <div class="col-md-12">
      <AlignInput v-model="section.Align" />
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.BackgroundColor" label="Background color"/>
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.Padding" label="Padding" />
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Heading</label>
        <textarea
          v-model="section.Content"
          class="form-control"
          rows="4"
        ></textarea>
      </div>
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.HeadingColor" label="Text color"/>
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.FontSize" label="Font size" />
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label>Descripiton</label>
        <textarea
          v-model="section.Description"
          class="form-control"
          rows="3"
        ></textarea>
      </div>
    </div>
    <div class="col-md-6">
      <ColorInput v-model="section.DescriptionColor" label="Text color"/>
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.DescriptionFontSize" label="Font size" />
    </div>
    <div class="col-md-6">
      <NumberInput v-model="section.DescriptionPadding" label="Padding" />
    </div>
  </div>
</template>

<script>
import ColorInput from './ColorInput'
import NumberInput from './NumberInput'
import AlignInput from './AlignInput'

export default {
  components: {
    NumberInput,
    AlignInput,
    ColorInput
  },

  props: {
    section: {
      type: [Object],
      default: null,
    },
  },
}
</script>
